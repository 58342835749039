import React from "react";
import Nodata from "../../../../Nodata";

const Purchase = ({ orderData, isTablet }) => {
  return (
    <div className="row justify-center">
    <div className="col-xl-10 col-lg-12">
      <h2 className="text-black font-w600 ml-3 mt-2">Appointments</h2>

      <div id="" className="basic-form">
        <div className="card-body">
          {
            orderData.map((item) => (
              <div
                key={item._id}
                className="custom-card card-border pt-4 pb-4 pl-4 pr-4 relative"
              >
                <div className="media items-list-2">
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 text-black">
                      {item.quantity}
                    </h5>
                   
                    <small className="text-primary font-w500 mb-3">
                      {
                        "On" +
                        " " +
                        item?.createdAt}
                    </small>
                    <span className="text-secondary mr-2 fo"></span>
                  </div>
                  <div className="media-footer align-self-center ml-auto d-block align-items-center">
                    <h3 className="mb-0 font-w600 text-secondary">
                      {"Rwf" + " " + item?.total_price}
                    </h3>
                  </div>
                </div>
                <span
                  className={`${
                    item?.status === "RECEIVED"
                      ? "bg-green-500"
                      : item?.status === "CANCELED" || item?.status === "PENDING"
                      ? "bg-yellow-500"
                      : "bg-red-500"
                  } border absolute right-4 text-white top-2 rounded border-gray-300 py-1 px-2 mb-0.5`}
                >
                  {item?.status}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  </div>
    )
};

export default Purchase;
