import React from "react";

import Header from "./Header";
import ClientDetails from "./InvoiceDetailsBody";
import Table from "./Table";


const CombinedComponent = React.forwardRef((props, ref) => {
  
  return (
    <div ref={ref}>
      
        <Header  />
        <ClientDetails />
        <Table/>
    
    </div>
  );
});

export default CombinedComponent;
