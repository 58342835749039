import { Row, Checkbox, notification } from "antd";
import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/14barber.png";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { axiosRequest } from "../../api";
import { setUserSession } from "../utils/common";
import Fade from "react-reveal/Fade";
import LoadingButton from "../LoadingButton";

import styles from "./Home.module.css";

const Login_URL = "team/login";

export default function App() {
  const [showPassword, setShowPassword] = useState(false);

  const [askAdminPermissionModel, setAskAdminPermissionModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const adminPermission = () => {
    let newState = !askAdminPermissionModel;
    setAskAdminPermissionModel(newState);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axiosRequest
      .post(Login_URL, {
        email: email,
        password: password,
      })
      .then((res) => {
        setLoading(false);
        const result = res.data;
        const { message } = result;
        toast.success(message);
        setUserSession(res.data.token, res.data.user);
        setTimeout(() => {
          navigate("/boards");
        }, 2000);
      })
      .catch((error) => {
        const errMsg = error.response.data.message;
        toast.error(errMsg);
      });
  };

  return (
    <>
      {/* <ToastContainer /> */}
      {/* ====================== Start::  AskAdminPermissionModel =============================== */}
      <Fade right>
        <div
          className={`min-h-full w-screen z-50 bg-opacity-30 backdrop-blur-sm fixed flex items-center justify-center px-4 ${
            askAdminPermissionModel === true ? "block" : "hidden"
          }`}
        >
          <div className="bg-white w-1/2 shadow-2xl rounded-lg p-4">
            <div className="card-title w-full flex  flex-wrap justify-center items-center  ">
              <h1 className="font-bold text-sm text-center w-11/12">
                Request admin permission
              </h1>
              <hr className=" bg-primary border-b w-full -mb-6" />
            </div>
            <div className="card-body">
              <form className="-mb-10 px-8">
                <div>
                  <h2 className="text-base m-4">
                    If you want to join our Team, Call Admin or Text him{" "}
                  </h2>
                </div>
                <div className="w-full flex justify-between">
                  <button
                    className="btn btn-danger light shadow-none"
                    onClick={(e) => adminPermission(e.preventDefault())}
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fade>
      {/* =========================== End::  AskAdminPermissionModel =============================== */}

      <div className="min-h-screen bg-black flex">
      <div className="m-auto grid grid-cols-1 lg:grid-cols-2 gap-4 p-4">
        {/* Logo Section */}
        <div className="flex items-center justify-center lg:justify-start">
          <div className="text-center ml-14">
           <img src={Logo} alt="Logo" className="mx-auto lg:mx-0 mb-4 w-30 h-10" />
          <h2 className="text-4xl text-white font-bold">Welcome to,</h2>
          <h2 className="text-4xl text-white font-bold">14 Barber Dashboard</h2>
            
            {/* <h1 className="text-5xl text-white font-bold"></h1> */}
          </div>
        </div>
        
        {/* Form Section */}
        <div className="bg-white p-8 rounded-xl shadow-xl">
          <h2 className="text-2xl font-bold mb-2 text-center text-[#111]">Sign In</h2>
          <p className="text-sm mb-8 text-center text-[#111]">Enter your Credentials</p>
          <form className="space-y-6" onSubmit={handleLogin}>
            <input 
              type="email" 
              placeholder="Email" 
              value={email} 
              onChange={e => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-md text-[#111]"
              required 
            />
            <input 
              type="password" 
              placeholder="Password" 
              value={password} 
              onChange={e => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-md text-[#111]"
              required 
            />
            <div className="flex justify-between items-center text-sm">
              <label className="flex items-center">
                <input type="checkbox" className="form-checkbox" />
                <span className="ml-2 text-[#111]">Remember me</span>
              </label>
              <Link to="/forget" className="text-[#111] hover:underline">
                Forgot your password?
              </Link>
            </div>
            <button 
              type="submit"
              disabled={loading}
              className="w-full bg-black text-white py-2 rounded-md hover:bg-back transition duration-300"
            >
              {loading ? 'Signing In...' : 'Sign In'}
            </button>
          </form>
          <p className="text-center mt-6 text-sm text-[#3c3c3c]">
            Not a Member yet? <button
                    className="text-black hover:underline"
                    onClick={adminPermission}
                  >
<strong>Sign Up</strong>                  </button>
                   {/* <Link to="/signup" className="text-black hover:underline"><strong>Sign Up</strong></Link> */}
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
    </>
  );
}
