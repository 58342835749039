import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { getUser } from "../utils/common";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHouse,
  faCartPlus,
  faClockRotateLeft,
  faCalendarCheck,
  faUserGroup,
  faPeopleGroup,
  faBagShopping,
  faGear,
  faRectangleList,
  faReceipt,
  faTruckField,
  faTruck,
  faUserPlus,
  faFileWaveform,
  faFileInvoiceDollar,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';

const iconMap = {
  faHouse,
  faCartPlus,
  faClockRotateLeft,
  faCalendarCheck,
  faUserGroup,
  faPeopleGroup,
  faBagShopping,
  faGear,
  faRectangleList,
  faReceipt,
  faTruckField,
  faTruck,
  faUserPlus,
  faFileWaveform,
  faFileInvoiceDollar,
  faChevronDown
};

const SidebarItem = ({ item }) => {
  const [open, setOpen] = useState(false);
  const isTablet = useMediaQuery("(max-width: 960px)");
  const user = getUser();
  const findType = user[0]?.type;

  const renderChildrenLinks = (children) =>
    children.map((child, index) => {
      if (child.role.includes(findType)) {
        return (
          <NavLink
            key={index}
            to={child.path || "#"}
            className={({ isActive }) =>
              `block px-4 py-2 text-sm  hover:bg-[#f5f1da] hover:text-[#000] ${isActive ? "bg-[#f5f1da] text-[#000]" : "text-[#fff]"}`
            }
          >
            {child.icon && <FontAwesomeIcon icon={iconMap[child.icon]} className="text-sm mr-2" />}
            {child.title}
          </NavLink>
        );
      }
      return null;
    });

  return (
    <div className={`my-1 ${isTablet ? 'bg-[#000]' : ''}`}>
      {item.childrens ? (
        <div>
          <div
            className="flex items-center justify-between px-4 py-2 text-sm text-[#fff] cursor-pointer hover:bg-[#f5f1da] hover:text-[#000]"
            onClick={() => setOpen(!open)}
          >
            <div className="flex items-center">
              {item.icon && <FontAwesomeIcon icon={iconMap[item.icon]} className="text-sm mr-2"/>}
              <span>{item.title}</span>
            </div>
            <FontAwesomeIcon icon={faChevronDown} className={`text-sm transform transition-transform ${open ? 'rotate-180' : ''}`} />
          </div>
          <div className={`pl-4 overflow-hidden transition-all duration-700 ease-in-out ${open ? 'max-h-60' : 'max-h-0'}`}>
            {renderChildrenLinks(item.childrens)}
          </div>
        </div>
      ) : (
        <NavLink
          to={item.path || "#"}
          className={({ isActive }) =>
            `flex items-center px-4 py-2 text-sm  hover:bg-[#f5f1da] hover:text-[#000] ${isActive ? "bg-[#f5f1da] text-[#000]" : "text-[#fff]"}`
          }
        >
          {item.icon && <FontAwesomeIcon icon={iconMap[item.icon]} className="text-sm mr-2" />}
          {item.title}
        </NavLink>
      )}
    </div>
  );
};

export default SidebarItem;
